<template>
  <div class="reclama">
    <div class="reclama__wrapper" v-for="data in findOffer" :key="data.id">
      <a :href="data.affiliate_url">
        <img class="reclama__img" :src="data.logo"/>
      </a>
      <div class="reclama__name">{{ data.name }}</div>
      <div class="reclama__stavka">
        <div class="reclama__stavka-title">Ставка</div>
        <div class="reclama__stavka-perc">от {{ data.epc }}%</div>
      </div>
      <a :href="data.affiliate_url">
        <button class="reclama__link">Получить</button>
      </a>
    </div>
  </div>
</template>

<script>

import json from '../../../../data.json'

export default {
  name: "reclama",
  data() {
    return {
      myJson: json,
      findOffer: [],
    }
  },
  props: {
    offers: {
      type: Array,
      default: true
    }
  },
  computed: {
    jsoncomp() {
      return json.data.offers;
    },

  },
  mounted() {
    this.getFilterOffers(this.offers)
  },
  methods: {
    getFilterOffers(offers) {
      console.log(offers)
      offers.forEach(offer => {
        this.findOffer.push(...this.jsoncomp.filter(item => item.id === offer.id));
      })
    }
  }
}

</script>