<template>
  <div class="lds-chat-bot__variable-block">
    <div
        v-for="modeItem in chatBotAnswers"
        :key="modeItem.value"
        v-model="answerOfChatBot"
        class="lds-chat-bot__radio-label"
        @click="$emit('actionCredit', modeItem.value)"
    >
      {{ modeItem.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "firstVariables",
  data() {
    return {
      answerOfChatBot: '',
    }
  },
  props: {
    chatBotAnswers: {
      type: Array,
    }
  },
}
</script>

<style scoped>

</style>