import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Custom styles
import './styles/style.scss';

Vue.config.productionTip = false;

new Vue({
  el: '#leads-adviser',
  router,
  render: h => h(App)
});
