<template>
  <div class="home">
   <chat-bot />
  </div>
</template>

<script>
// @ is an alias to /src

import ChatBot from "@/components/ChatBot/ChatBot.vue";
export default {
  name: 'Home',
  components: {
    ChatBot
  }
}
</script>
