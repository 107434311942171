<template>
  <div class="lds-chat-bot">
    <div class="lds-chat-bot__header">
      <div class="lds-chat-bot__information">
        <div class="lds-chat-bot__avatar">
          <img src="https://chat-bot.leads.su/img/avatar.png">
        </div>
        <div class="lds-chat-bot__title">
          <div class="lds-chat-bot__name">Елена Викторовна</div>
          <div class="lds-chat-bot__position">Бесплатный финансовый консультант</div>
        </div>
      </div>
    </div>
    <div class="lds-chat-bot__messages" ref="chatbox" v-if="showInputChat">
      <div class="lds-chat-bot__block-chat" v-for="(message, index) in botsMessages" :key="index">
        <div class="lds-chat-bot__bot-message" v-if="message.question !== undefined">
          {{ message.question }}
        </div>
        <div class="lds-chat-bot__user-message" v-if="message.answer !== undefined">
          {{ message.answer }}
        </div>
        <div class="lds-chat-bot__load-message" v-if="message.loading">
          <div class="lds-chat-bot__dots">...</div>
        </div>
      </div>
    </div>
    <div class="lds-chat-bot__message-box" v-if="showInputChat">
      <first-variables :chat-bot-answers="chatBotAnswers[0]"
                       @actionCredit="actionCredit"
                       v-if="showAnswers"
      />
      <div v-if="showInputChat">
        <div class="lds-chat-bot__input-block" v-if="this.botsMessages.length === 3">
          <input v-model="messagesAnswers.nameMessage" class="lds-chat-bot__input-message">
          <button @click="sendMessage" class="lds-chat-bot__button">Отправить</button>
        </div>
        <div class="lds-chat-bot__input-block" v-if="this.botsMessages.length === 4">
          <input v-model="messagesAnswers.mailMessage" class="lds-chat-bot__input-message"/>
          <button @click="sendMessage" class="lds-chat-bot__button">Отправить</button>
        </div>
        <div class="lds-chat-bot__input-block" v-if="this.botsMessages.length === 5">
          <input v-model="messagesAnswers.phoneMessage" class="lds-chat-bot__input-message"/>
          <button @click="sendMessage" class="lds-chat-bot__button">Отправить</button>
        </div>
      </div>
    </div>
    <div class="lds-chat-bot__messages-recl" ref="chatbox" v-if="offerHideOne">
      <div class="lds-chat-bot__bot-message" v-if="offerMessagesWithQuestions[0].question !== undefined">
        {{ offerMessagesWithQuestions[0].question }}
      </div>
      <div class="reclama">
        <div class="reclama__wrapper" v-for="data in findOfferOne" :key="data.id">
          <a :href="data.affiliate_url">
            <img class="reclama__img" :src="data.logo"/>
          </a>
          <div class="reclama__name">{{ data.name }}</div>
          <div class="reclama__stavka">
            <div class="reclama__stavka-title">Ставка</div>
            <div class="reclama__stavka-perc">от {{ data.epc }}%</div>
          </div>
          <a :href="data.affiliate_url">
            <button class="reclama__link">Получить</button>
          </a>
        </div>

      </div>
      <div v-if="offerHideTwo">
        <div class="lds-chat-bot__bot-message" v-if="offerMessagesWithQuestions[1].question !== undefined">
          {{ offerMessagesWithQuestions[1].question }}
        </div>
        <div class="reclama">
          <div class="reclama__wrapper" v-for="data in findOffer" :key="data.id">
            <a :href="data.affiliate_url">
              <img class="reclama__img" :src="data.logo"/>
            </a>
            <div class="reclama__name">{{ data.name }}</div>
            <div class="reclama__stavka">
              <div class="reclama__stavka-title">Ставка</div>
              <div class="reclama__stavka-perc">от {{ data.epc }}%</div>
            </div>
            <a :href="data.affiliate_url">
              <button class="reclama__link">Получить</button>
            </a>
          </div>
        </div>

      </div>
      <first-variables :chat-bot-answers="offerShowMore[0]"
                       @actionCredit="actionOffer"
      />
    </div>
  </div>
</template>

<script>
import FirstVariables from "@/components/ChatBot/components/firstVariables.vue";
import reclama from "@/components/ChatBot/components/reclama.vue";
import Vue from 'vue';
import axios from "axios";
import json from '../../../data.json'

export default {
  name: "ChatBot",
  components: {FirstVariables, reclama},
  data() {
    return {
      myJson: json,
      findOfferOne: [],
      findOffer: [],
      chatBotAnswers: [],
      offerShowMore: [[{
        value: 1,
        name: 'Показать еще?',
      }]],
      showAnswers: true,
      offerHideOne: false,
      offerHideTwo: false,
      showInputChat: true,
      messagesAnswers: {
        sumMessage: null,
        creditHistory: null,
        nameMessage: null,
        mailMessage: null,
        phoneMessage: null,
      },
      enterQuestionUser: '',
      botsMessages: [
        {
          question: 'Привет! Меня зовут Елена. Я бесплатно помогу Вам подобрать лучшее предложение среди микрозаймов. Одобренный займ вы сможете получить уже через несколько минут.\n' +
              ' Какая сумма Вам нужна?',
          answer: undefined,
          loading: false,
        },
      ],
      botsMessagesWithQuestions: [
        {
          question: 'Привет! Меня зовут Елена. Я бесплатно помогу Вам подобрать лучшее предложение среди микрозаймов. Одобренный займ вы сможете получить уже через несколько минут.\n' +
              ' Какая сумма Вам нужна?',
          answer: undefined,
          loading: false,
        }, {
          question: 'Знаете ли вы какая у вас кредитная история?',
          answer: undefined,
          loading: false,
        }, {
          question: 'Как Вас зовут?',
          answer: undefined,
          loading: false,
        }, {
          question: 'Укажите пожалуйста ваш E-mail',
          answer: undefined,
          loading: false,
        }, {
          question: 'Укажите пожалуйста Ваш номер телефона для смс подтверждений',
          answer: undefined,
          loading: false,
        }
      ],
      answerForChatBot: [
        {
          answer: [
            {
              value: '5000',
              name: 'до 5000',
            },
            {
              value: '15000',
              name: 'до 15000',
            },
            {
              value: '30000',
              name: 'до 30000',
            }
          ]
        },
        {
          answer: [
            {
              value: 'notknow',
              name: 'Не знаю',
            },
            {
              value: 'bad',
              name: 'Плохая',
            },
            {
              value: 'good',
              name: 'Хорошая',
            }
          ]
        }
      ],
      offerMessagesWithQuestions: [
        {
          question: 'Спасибо за Вашу заявку, вот лучшие предложения доступные Вам для оформления прямо сейчас. Имейте ввиду, что для увеличения шанса на получение необходимо подать не менее 3 заявок в разные МФО.',
          answer: undefined,
          loading: false,
        }, {
          question: 'В этих МФО самые выгодные условия по займам на сегодняшний день, но если по каким то причинам их недостаточно, мы можем подобрать еще несколько МФО с хорошими условиями',
          answer: undefined,
          loading: false,
        },
      ],
      offersForReclama: [
        [{
          id: 1051,
          name: 'Веб-займ',
        }, {
          id: 530,
          name: 'Займер',
        }, {
          id: 10394,
          name: 'MoneyMan',
        }, {
          id: 1044,
          name: 'Турбозайм',
        }, {
          id: 9692,
          name: 'Lime',
        }],
        [{
          id: 693,
          name: 'Е-капуста',
        }, {
          id: 1354,
          name: 'Cash U',
        }, {
          id: 522,
          name: 'Webbankir',
        }],
        [{
          id: 9692,
          name: 'Lime',
        }, {
          id: 9863,
          name: 'Max.Credit private',
        }, {
          id: 899,
          name: 'Zaymigo',
        }],
        [{
          id: 9667,
          name: 'Надо денег',
        }, {
          id: 140,
          name: 'Вивус',
        }, {
          id: 9637,
          name: 'Credit Plus',
        }],
        [{
          id: 9757,
          name: 'Oneclickmoney',
        }, {
          id: 288,
          name: 'Kredito 24',
        }, {
          id: 9206,
          name: 'Credit7',
        }]
      ]
    }
  },
  computed: {
    jsoncomp() {
      return json.data.offers;
    },
  },
  mounted() {
    this.chatBotAnswers.push(this.answerForChatBot[0].answer);
  },
  methods: {
    handleQuestion(answer) {
      axios({
        method: 'get',
        url: 'https://chat-bot.leads.su/file?mailMessage=' + answer.mailMessage + '&nameMessage=' + answer.nameMessage + '&phoneMessage=' + answer.phoneMessage,
        responseType: 'json',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      })
          .then(response => (
                  console.log(response)
              )
          )
          .catch(error => console.log(error));
    },
    async actionCredit(value) {
      if (value === '5000' || value === '15000' || value === '30000') {
        this.messagesAnswers.sumMessage = value;
        this.chatBotAnswers = []
        this.botsMessages[0] = {
          question: 'Привет! Я – Елена. Я могу подобрать для тебя лучшее предложение среди микрозаймов. Какая сумма Вам нужна?',
          answer: 'Нужен займ ' + value + ' рублей',
          loading: true,
        };
        setTimeout(() => {
          this.botsMessages[0].loading = false;
          this.botsMessages[1] = {
            question: this.botsMessagesWithQuestions[1].question,
            answer: undefined,
            loading: false,
          };
          this.chatBotAnswers.push(this.answerForChatBot[1].answer);

          this.scrollTick();
        }, 2000);
        this.scrollTick();
      } else if (value === 'notknow' || value === 'bad' || value === 'good') {
        this.messagesAnswers.creditHistory = value;
        this.chatBotAnswers = []
        this.botsMessages[1] = {
          question: this.botsMessagesWithQuestions[1].question,
          answer: value === 'notknow' ?
              'Я не знаю свою кредитную историю' :
              value === 'bad' ?
                  'У меня плохая кредитная история' : 'У меня хорошая кредитная история',
          loading: true,
        };
        setTimeout(() => {
          this.showAnswers = false;
          this.botsMessages[1].loading = false;
          this.botsMessages[2] = {
            question: this.botsMessagesWithQuestions[2].question,
            answer: undefined,
            loading: false,
          };
          this.scrollTick();
        }, 2000);
        this.scrollTick();
      }
    },

    getFilterOffers(offers) {
      offers.forEach(offer => {
        this.findOffer.push(...this.jsoncomp.filter(item => item.id === offer.id));
      });
      this.findOffer = [...new Map(this.findOffer.map(item => [item['id'], item])).values()];
    },
    getFilterOffersOne(offers) {
      offers.forEach(offer => {
        this.findOfferOne.push(...this.jsoncomp.filter(item => item.id === offer.id));
      });
      this.findOffer = [...new Map(this.findOffer.map(item => [item['id'], item])).values()];
    },

    actionOffer(value) {
      if (value === 1) {
        this.offerHideTwo = true;
        this.getFilterOffers(this.offersForReclama[1]);
        this.offerShowMore[0] = [{
          value: 2,
          name: 'Показать еще?',
        }]
      }
      if (value === 2) {
        this.getFilterOffers(this.offersForReclama[2]);
        this.offerShowMore[0] = [{
          value: 3,
          name: 'Показать еще?',
        }]
      }
      if (value === 3) {
        this.getFilterOffers(this.offersForReclama[3]);
        this.offerShowMore[0] = [{
          value: 4,
          name: 'Показать еще?',
        }]
      }
      if (value === 4) {
        this.getFilterOffers(this.offersForReclama[4]);

        this.offerShowMore[0] = [{
          value: 5,
          name: 'Вернуться к лучшим предложением',
        }]

      }
      if (value === 5) {
        this.findOffer = [];
        this.offerHideTwo = false;
        this.offerShowMore[0] = [{
          value: 1,
          name: 'Показать еще?',
        }]
      }
      this.scrollTick();
    },

    isEmpty(str) {
      if (str === null || str.trim() == '')
        return true;

      return false;
    },

    scrollTick() {
      this.$nextTick(() => {
        this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight
      })
    },

    buildChat(field, startNumArray, endNumArray, column) {
      if (this.isEmpty(this.messagesAnswers[field])) return;

      Vue.set(this.botsMessages, startNumArray, {
        question: this.botsMessagesWithQuestions[startNumArray].question,
        answer: this.messagesAnswers[field],
        loading: true,
      });

      setTimeout(() => {
        Vue.set(this.botsMessages, startNumArray, {
          question: this.botsMessagesWithQuestions[startNumArray].question,
          answer: this.messagesAnswers[field],
          loading: false,
        });

        Vue.set(this.botsMessages, endNumArray, {
          question: this.botsMessagesWithQuestions[endNumArray].question,
          answer: undefined,
          loading: false,
        });

        this.enterQuestionUser = [];
        this.scrollTick();
      }, 2000);

      this.scrollTick();
    },

    sendMessage(val) {
      val.preventDefault();

      if (this.botsMessages.length == 3) {
        this.buildChat('nameMessage', 2, 3, 0);
      }

      if (this.botsMessages.length == 4) {
        this.buildChat('mailMessage', 3, 4, 1);
      }

      if (this.botsMessages.length == 5) {
        if (this.isEmpty(this.messagesAnswers.phoneMessage)) return;

        Vue.set(this.botsMessages, 4, {
          question: this.botsMessagesWithQuestions[4].question,
          answer: this.messagesAnswers.phoneMessage,
          loading: true,
        });

        setTimeout(() => {
          Vue.set(this.botsMessages, 4, {
            question: this.botsMessagesWithQuestions[4].question,
            answer: this.messagesAnswers.phoneMessage,
            loading: false,
          });
          this.getFilterOffersOne(this.offersForReclama[0]);
          this.showInputChat = false;
          this.offerHideOne = true;

          this.handleQuestion(this.messagesAnswers)
        }, 2000);
        this.scrollTick();
      }
    },
  }
}
</script>
